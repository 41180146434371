<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation title="Account Activation" :hideLogin="true" />

    <div class="border centerAuthDiv">
        <div class="row">

            <div v-if="!isActivated" class="col-12 pb-3">
                <input type="password" v-model="password1" placeholder="Password" class="form-control" />
            </div>

            <div v-if="!isActivated" class="col-12 pb-3">
                <input type="password" v-model="password2" placeholder="Reconfirm password" class="form-control"/>
            </div>

            <div v-if="!isActivated" class="col-12 col-lg-8 pb-3">
                You will be redirected to a login page after set password
            </div>

            <div v-if="!isActivated" class="col-12 col-lg-4 pb-0 text-end">
                <button class="btn btn-secondary" @click="setPassword">Set Password</button>
            </div>

        </div>
    </div>    
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, computed, inject, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'

export default {
    name: 'Activate',
    components: { TopNavigation, Alert },
    setup () {

        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const store = useStore()
        const { getDisplayName, updDisplayName, getAvatar, updAvatar } = useStore()

        const isActivated = ref(false) // Indicate if the activationCode activated successfully. Success will proceed to reset password.
        const password1 = ref(null)
        const password2 = ref(null)

        const displayName = ref('')
        const avatar = ref('')

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
            console.info('index', index)
        }

        const setPassword = () => {
            // console.info('set password', password1.value, password2.value)

            if (password1.value !== password2.value) {
                console.info('alert')
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Password and reconfirm password do not match. '
                })

            } else {
                // Proceed tor set password (axios)
                const p = {
                    password: password1.value
                }
                
                let url = '/auth/resetPassword/' + route.params.activationCode
                console.info('setPassword p', JSON.stringify(p))

                axios.put( url, p )
                    .then((res) => {

                        console.info('setPassword res', JSON.stringify(res))
                        if (res.data.status === 1) {

                            // Activate successfully
                            localStorage.setItem('userId', res.data.data.userId)
                            localStorage.setItem('sessionId', res.data.data.sessionId)
                            localStorage.setItem('userStatus', 'ACTIVE') 
                            // localStorage.setItem('displayName', res.data.data.displayName) 
                            // localStorage.setItem('avatar', res.data.data.avatar) 

                            displayName.value = computed({
                                get: () => store.getDisplayName.value,
                                set: store.updDisplayName(res.data.data.displayName),
                            })
                            displayName.value = store.getDisplayName.value 

                            avatar.value = computed({
                                get: () => store.getAvatar.value,
                                set: store.updAvatar(res.data.data.avatar),
                            })
                            avatar.value = store.getAvatar.value 

                            setTimeout(() => {
                                router.push('/uploadDoc')
                                .then(() => {
                                    // refresh the page so that can capture sessionId in store
                                    router.go() 
                                })
                            , 5000})

                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Password is updated. You will be redirected to login page shortly."
                            })
                        } 
                    })
                    .catch((error) => {
                        console.log('setPassword | FAILURE', JSON.stringify(error))

                        if (error.status === 'refId_expired') {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: 'Your activation link is expired. (' + error.code + ')'
                            })

                        } else if (error.status === 'invalid_action' || error.status === 'hacking_attempt' || error.status === 'refId_not_found'){
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: 'Your activation link is not working. Please report the error (error code) to our support if you continue to face the same problem. (' + error.code + ')'
                            })

                        } else {

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.convError(error)
                            })
                        }
                    })

            }
        }

        onMounted(() => {
            console.info('onMounted')

            if (route.params.activationCode === null || route.params.activationCode === undefined) {
                // Redirect to page not found if no activationCode was detected
                router.push('/pageNotFound')
            } 

        })

        return { 
            alert, closeAlert, isActivated, 
            password1, password2, setPassword 
        }
    }
}
</script>

<style>

</style>